<template>
    <v-container :class="{ rtl: selectedLang && selectedLang==='ar' }" style="max-width: 800px" class="form-container" v-if="responseData">
        <img class="logo-mobile" :src="logoUrl" v-if="showLogo" >
        <h1 class="form-title">
            {{ responseData.pageTitle }}
        </h1>
        <h4 v-if="responseData.refText" class="referral-message">
            {{responseData.refText}}
        </h4>
        <v-form style="display: flex; flex-wrap: wrap; margin-top: 20px;" class="form-data">
            <v-select
                v-model="selectedLang"
                :items="languages"
                item-text="name"
                item-value="abreviation"
                @change="$emit('changeLanguage', selectedLang)"
                class="lang-select"
                v-if="languages && languages.length > 1"
            />
            <v-container 
                v-for="field in responseFields" 
                :key="field.id"
                :style="{width: field.fullWidth ? '100%' : '50%', position: 'relative'}"
                class="signup-form"
                v-show="field.isHidden === false || (field.forceShow && forceShowEmailField)"
            >
                <p class="field-label">
                    {{field.title}} 
                    <span v-if="field.required"> * </span>
                </p>

                <div v-if="field.type === 'text'" style="display: flex; gap: 20px">
                    <v-select
                        v-if="field.withCountryCode" 
                        v-model="dataObject.country_code"
                        :items="allCountriesObject"
                        item-value="code"
                        item-text="countryDispName"
                        outlined
                        hide-details
                        style="width: 1%; min-width: 130px;"
                    ></v-select>
                    <v-text-field 
                        v-model="dataObject[field.dataVar]"
                        :required="field.required"
                        :placeholder="`${enterString} ${field.title}`"
                        outlined
                        hide-details
                    >
                        <template v-if="field.dataVar == 'account_number' && responseData.accountNumberPrefix" #prepend-inner>
                            <span>{{ responseData.accountNumberPrefix }}</span>
                        </template>
                    </v-text-field>
                </div>

                <v-textarea 
                    v-if="field.type === 'textarea'"
                    v-model="dataObject[field.dataVar]"
                    :required="field.required"
                    :placeholder="`${enterString} ${field.title}`"
                    outlined
                    hide-details
                />
                <v-select 
                    v-else-if="field.type === 'select'"
                    v-model="dataObject[field.dataVar]"
                    :items="responseData[field.select]"
                    :item-text="field.itemText"
                    :item-value="field.itemValue"
                    outlined
                    :required="field.required"
                    :placeholder="`${enterString} ${field.title}`"
                    hide-details
                />
                <v-text-field 
                    v-else-if="field.type === 'date' && !datePickerOpen"
                    v-model="dataObject[field.dataVar]"
                    @click="datePickerOpen = true"
                    outlined
                    hide-details
                    :placeholder="`${enterString} ${field.title}`"
                />
                <v-date-picker
                    v-else-if="field.type === 'date' && datePickerOpen"
                    v-model="dataObject[field.dataVar]"
                    @input="datePickerOpen = false"
                    @change="datePickerOpen = false"
                    :color="primaryColor"
                    style="position: absolute; left: 0; top: 50px; z-index: 10"
                    elevation="3"
                    ref="datePicker"
                >
                <template>
                    <v-btn style="position: absolute; top: 0; right:0" icon @click="datePickerOpen = false">
                    <v-icon color="white" >mdi-close</v-icon>
                    </v-btn>
                </template>
                </v-date-picker>
            
                <v-select
                    v-else-if="field.type==='address'"
                    v-model="address"
                    :items="autocompleteItems"
                    outlined
                    item-text="description"
                    item-value="place_id"
                    @change="onAddressChange"
                    return-object
                    :placeholder="`${enterString} ${field.title}`"
                    hide-details
                >
                    <template v-slot:prepend-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-text-field 
                                    v-model="address.description"
                                    @input="getAddressDetails"
                                    outlined
                                    hide-details
                                    :placeholder="`${enterString} ${field.title}`"
                                />
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-select>
            </v-container>
            <v-container>
                <v-checkbox v-if="showEmail" hide-details color="black" v-model="dataObject.allow_email" :label="getTranslation('SW_ALLOWEMAIL')" />
                <v-checkbox v-if="showPhone" hide-details color="black" v-model="dataObject.allow_sms" :label="getTranslation('SW_ALLOWSMS')" />
                <v-checkbox v-if="getTermsLink" hide-details color="black" v-model="dataObject.terms_conditions">
                    <template  v-slot:label>
                        <span @click="openLink" v-html="getTermsLink"></span>
                    </template>
                </v-checkbox> 
                <p style="margin-top: 20px; color:rgba(0,0,0,.6)" v-if="getConsentMessage" v-html="getConsentMessage" />
            </v-container>
            <ReCaptchaCheckbox
                ref="reCaptcha" 
                style="margin-left: 15px; margin-top: 10px" 
                @onRecaptchaVerified="onRecaptchaVerified" 
                @onRecaptchaExpired="onRecaptchaExpired" 
            />


            <v-btn :loading="loading" class="register-button" @click="submitForm">
                {{getTranslation('SW_REGISTER')}}
            </v-btn>
        </v-form>
        <WidgetDialog :message="dialogMessage" @closeDialog="dialogMessage=''" />
    </v-container>
</template>

<script>
import WidgetDialog from './WidgetDialog.vue'
import ReCaptchaCheckbox from './ReCaptchaCheckbox.vue'

export default {
    components:{
        WidgetDialog,
        ReCaptchaCheckbox
    },
    props:{
        responseData:{
            type: Object,
            required: true,
        },
        primaryColor:{
            type: String,
            required: true
        },
        logoUrl:{
            type: String,
            required: false,
        },
        token:{
            type: String,
            required: false,
        },
        refToken:{
            type: String,
            required: false,
        },
        businessId:{
            type: String,
            required: false,
        },
        showLogo:{
            type: Boolean,
            required: true,
        },
    },

    data(){
        return{
            datePickerOpen: false,
            autocompleteItems: [],
            address: {
                description: '',
                place_id: ''
            },
            addressDetails: {},
            dataObject:{
                email: '',
                phone: '',
                country_code: '',
                firstName: '',
                lastName: '',
                gender: '',
                birthDate: '',
                // notes: '',
                postalcode: '',
                country: '',
                mail: '',
                10: '',
                11: '',
                12: '',
                13: '',
                14: '',
                province: '',
                city: '',
                lat: '',
                lng: '',
                countryShort: '',
                route: '',
                street_number: '',
                allow_sms: false,
                allow_email: false,
                terms_conditions: false,
                'g-recaptcha-response': '',
                account_number: null,
                account_number_prefix: this.responseData && this.responseData.accountNumberPrefix ? this.responseData.accountNumberPrefix : undefined ,
            },
            dialogMessage: '',
            loading: false,
            selectedLang: null,
        }
    },
    mounted(){
        const selectedLanguage = this.responseData.lang
        const selected = this.languages.find(lang=> lang.abreviation === selectedLanguage)
        if(selected) this.selectedLang = selected.abreviation
        if(this.defaultCountry) this.dataObject.country_code = this.defaultCountry
        if(this.selectedLang==='ar') this.$vuetify.rtl = true
    },
    computed:{
        fields(){
            return [
                { id: -2, dataVar: 'email', textValue: 'SW_EMAIL', value: null, type: 'text', fullWidth: true, forceShow: true},
                { id: -1, dataVar: 'phone', textValue: 'SW_PHONE', value: null, type: 'text', fullWidth: true, withCountryCode: true},
                { id: 101, dataVar: 'account_number', textValue: 'SW_ACCOUTNUMBER', value: null, type: 'text' },
                { id: 0, dataVar: 'firstName', textValue: 'SW_FIRSTNAME', value: null, type: 'text' },
                { id: 1, dataVar: 'lastName', textValue: 'SW_LASTNAME', value: null, type: 'text'},
                { id: 2, dataVar: 'gender', textValue: 'SW_GENDER', value: null, type: 'select', select: 'genders', itemText: 'name', itemValue: 'gender_pk'},
                { id: 3, dataVar: 'birthDate', textValue: 'SW_DOB', value: null, type: 'date'},
                // { id: 4, dataVar: 'notes', textValue: 'SW_NOTES', value: null, type: 'textarea', fullWidth: true},
                { id: 5, dataVar: 'address', textValue: 'SW_ADDRESS', value: null, type: 'address'},
                { id: 6, dataVar: 'postalcode', textValue: 'SW_POSTALCODE', value: null, type: 'text'},
                { id: 7, dataVar: 'country', textValue: 'SW_COUNTRY', value: null, type: 'text'},
                // { id: 8, dataVar: 'mail', value: false, type: 'radio', text: ''},
                { id: 10, dataVar: '10', value: null, type: 'text'},
                { id: 11, dataVar: '11', value: null, type: 'text'},
                { id: 12, dataVar: '12', value: null, type: 'text'},
                { id: 13, dataVar: '13', value: null, type: 'text'},
                { id: 14, dataVar: '14', value: null, type: 'text'},
            ]
        },
        languages(){
            if(!this.responseData || !this.responseData.languages) return null
            return this.responseData.languages
        },
        allCountriesObject(){
            if(!this.responseData || !this.responseData.allCountriesObject) return null
            return this.responseData.allCountriesObject.countryList
        },
        defaultCountry(){
          if(!this.responseData || !this.responseData.allCountriesObject) return null
          return this.responseData.allCountriesObject?.selectedCountry?.code  
        },
        responseFields(){
            if(!this.responseData || !this.responseData.crmSettings) return null
            return this.fields
            .map((field) => {
                const crmSetting = this.responseData.crmSettings.find((setting) => setting.id === field.id);
                if (crmSetting) { 
                    const title = field.textValue ? this.getTranslation(field.textValue) : crmSetting.text
                    return { ...field, ...crmSetting, title }
                }
                return field;
            })
            .sort((a, b) => parseInt(a.order) - parseInt(b.order));
        },
        enterString(){
            return this.getTranslation('SW_ENTER')
        },
        termsObject(){
            return this.responseData?.crmSettings?.find((setting) => setting.id === 100);
        },
        getTermsLink(){
            const link = this.getTranslation('SW_TERMS')
            const url = this.termsObject?.text
            if(!url) return null
            const urlPlaceholder = '{terms_conditions_url}';
            const formatted = link.replace(urlPlaceholder, url);
            return formatted;
        },
        getConsentMessage(){
            const businessName = this.responseData?.businessName
            if(!businessName) return
            const submitTermsText = this.getTranslation('SW_SUBMIT_TERMS')
            const termsConsentText = this.getTranslation('SW_CONSENT_TERMS')
            const businessNamePlaceholder = '{business_name}'
            const formattedConsentMessage = `${submitTermsText ? submitTermsText.replace(businessNamePlaceholder, `<b> ${businessName} </b>`) : ''} <br> ${termsConsentText || ''}`;
            return formattedConsentMessage;
        },
        showEmail(){
            const emailField = this.responseData?.crmSettings?.find((setting) => setting.id === -2)
            if(!emailField) return false
            return emailField.isHidden == false
        },
        showPhone(){
            const phoneField = this.responseData?.crmSettings?.find((setting) => setting.id === -1)
            if(!phoneField) return false
            return phoneField.isHidden == false
        },
        forceShowEmailField(){
            return !this.showEmail && !this.showPhone
        }
    },
    methods: {
        getTranslation(strToFind){
            return this.responseData?.translations?.data?.find(str => str.key === strToFind)?.string
        },
        openLink(event) {
            const targetElement = event.target;
            if (targetElement.tagName === "A" || targetElement.closest("a")) {
                window.open(targetElement.href, "_blank");
            }
        },
       async getAddressDetails() {
            const autocompleteService = await new window.google.maps.places.AutocompleteService();
            if (this.address) {
                autocompleteService.getPlacePredictions({ input: this.address.description }, predictions => {
                this.autocompleteItems = predictions;
                });
            } else {
                this.autocompleteItems = [];
            }
        },
        
        async onAddressChange(selectedItem) {
            const placeService = await new window.google.maps.places.PlacesService(document.createElement('div'))
            placeService.getDetails({ placeId: selectedItem.place_id }, (place, status) => {
                if (status === 'OK') {
                    const addressComponents = place.address_components;
                    const location = place.geometry.location;

                    for (const component of addressComponents) {
                        if (component.types.includes('country')) {
                             this.dataObject.country = component.long_name;
                        } else if (component.types.includes('administrative_area_level_1')) {
                             this.dataObject.province = component.long_name;
                        } else if (component.types.includes('locality') || component.types.includes('postal_town')) {
                             this.dataObject.city = component.long_name;
                        }
                    }
                    this.dataObject.lat = location.lat();
                    this.dataObject.lng = location.lng();

                    this.dataObject.countryShort = place.address_components.find((component) =>
                        component.types.includes('country')
                    )?.short_name;

                    for (const component of addressComponents) {
                        if (component.types.includes('route')) {
                            this.dataObject.route = component.long_name;
                        } else if (component.types.includes('street_number')) {
                            this.dataObject.street_number = component.long_name;
                        }
                    }
                }
            });
        },
        onRecaptchaVerified(response){
            console.log(response);
            this.dataObject['g-recaptcha-response'] = response
            
        },
        onRecaptchaExpired(){
            this.dataObject['g-recaptcha-response'] = ''
        },
        async submitForm(){
            const that = this
            this.loading = true
            const data = new URLSearchParams();
            const extraParams = this.token?`?token=${that.token}`:this.refToken && this.businessId?`?ref=${this.refToken}&b=${this.businessId}`:''
            data.append('url', `${process.env.VUE_APP_GET_SIGNUP_API_BASE_URL}${extraParams}`)
            if(this.token) data.append('token', this.token)
            if(this.refToken) data.append('ref', this.refToken)
            if(this.businessId) data.append('b', this.businessId)
            Object.entries(this.dataObject).forEach(([key, value]) => {
                let val = value
                if(['allow_sms', 'allow_email', 'terms_conditions'].includes(key)) val = value == true ? 1 : 0
                if(key==='birthDate' && val && val.length) {
                    val = val.substr(5,5)
                }
                data.append(key, val);
            });
            const headers = {
                'Authorization': `Bearer test`, 
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            await fetch(
                process.env.VUE_APP_POST_SIGNUP_API_BASE_URL,
                {   
                    method: 'POST',
                    headers: headers,
                    body: data
                }
            )
            .then(response => response.text())
            .then(data => {
                try{
                    const dataObj = JSON.parse(data)
                    that.loading = false
                    if(dataObj.message) that.dialogMessage = dataObj.message
                    if(dataObj.status === 'OK') {
                        const redirectTo = that.responseData.webappuri ? that.responseData.webappuri : process.env.VUE_APP_BUSINESS_PORTAL_WEBSITE
                        window.location.href = redirectTo
                    }
                    that.$refs?.reCaptcha?.resetRecaptcha()
                }
                catch(error){
                    that.showMessageAndCloseDialog('ERROR')
                }
            })
            .catch(error => {
                try{
                    const errorObj = JSON.parse(error)
                    if(errorObj.message) that.dialogMessage = errorObj.message
                    that.loading = false
                    that.$refs?.reCaptcha?.resetRecaptcha()
                }
                catch(error){
                    that.showMessageAndCloseDialog('ERROR')
                }
            });
        },
        closeDialog(){
            this.message = null
        },
        showMessageAndCloseDialog(msg){
            this.dialogMessage = msg
            this.$refs?.reCaptcha?.resetRecaptcha()
            this.loading = false
        }
    }
}
</script>

<style lang="less">
.form-container{
    &.rtl {
        direction: rtl;
        .lang-select{
            left: 10px !important;
            right: unset !important;
        }
    }
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    box-shadow: 4px 4px 8px 0px #4545450F;
    max-height: 80vh;
    overflow: scroll;
    padding: 40px !important;
    position: relative;
    background-color: white;
    .form-title{
        font-size: 28px;
        font-weight: 600 !important;
        padding-left: 10px;
    }
    .referral-message{
        padding-left: 10px;
        font-weight: 500;
    }
    .register-button{
        color: white !important;
        background-color: var(--primary-color) !important;
        width: 100% !important;
        font-size: 16px !important;
        text-transform: unset !important;
        padding: 25px !important;
        border-radius: 10px;
        margin-top: 24px;
        margin-bottom: 0;
        box-shadow: none !important;
    }
    .lang-select{
        position: absolute;
        top: 0;
        right: 10px;
        width: 150px;
    }
}
.logo-mobile{
    display: none;
}
.signup-form{
    .field-label{
        font-size: 16px;
        margin-bottom: 8px !important;
    }
    .v-text-field{
        fieldset{
            border-radius: 13px;
            border: 1px solid #9C9C9C;
        }
    }
    .v-text-field--outlined.v-input--has-state fieldset, .v-text-field--outlined.v-input--is-focused fieldset {
        border: 2px solid var(--primary-color);
    }
}
.v-menu__content, .v-picker--date{
        border-radius: 10px !important;
    }
        .v-input--selection-controls{
    margin-top: 0 !important;
    padding-top: 8px !important;
}
.v-input__prepend-inner{
    padding-right: 8px !important;
    margin-top: 21px !important;
}
@media screen and (max-width: 550px) {
    .signup-form{
        width: 100% !important;
    }
    .form-container{
        max-width: unset !important;
        min-height: 100vh;
        overflow: scroll !important;
        padding: 48px 12px !important;
    }
    .form-title{
        font-size: 20px !important
    }
    .form-data{
        margin-top: 4px !important;
    }
    .logo-mobile{
        height: 90px;
        display: block;
        margin: 0 auto;
        margin-bottom: 40px;
    }
    .v-text-field input{
        font-size: 14px;
    }
    .v-input--selection-controls .v-input__slot>.v-label, .v-input--selection-controls .v-radio>.v-label, .v-select__selection--comma{
        font-size: 14px !important
    }
    .v-input__slot .v-icon.v-icon{
        font-size: 20px;
    }
    .form-container .register-button{
        margin-top: 12px;
    }
}

</style>