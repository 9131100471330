<template>
<div>
  <v-app v-if="responseData" :style="`--primary-color: ${mainColor}`">
    <div class="upper-circle" :style="{backgroundColor: backgroundColor, borderColor: mainColor}" />
    <div class="bottom-circle" :style="{backgroundColor: backgroundColor, borderColor: mainColor}" />
    <img v-if="showLogo" class="logo" :src="logoUrl" />
    <v-container style="z-index: 100" class="main-container">
      <SignUpForm 
        :responseData="responseData" 
        :primaryColor="mainColor" 
        :logoUrl="logoUrl" 
        :token="token" 
        :showLogo="showLogo" 
        :refToken="refToken"
        :businessId="businessId"
        @changeLanguage="changeLanguage"
      />
    </v-container>
  </v-app>
  </div>
</template>

<script>
import SignUpForm from './components/SignUpForm.vue'
import axios from 'axios';

export default {
  name: 'App',
  components: {
    SignUpForm
  },
  data(){
    return{
      responseData: null,
      token: null,
      showLogo: false,
      refToken: null,
      businessId: null,
      language: null,
    }
  },
  computed:{
    mainColor(){
      return this.responseData?.buttonColor || '#7F56DA'
    },
    backgroundColor(){
      return `${this.mainColor}40`
    },
    logoUrl(){
      const logo = this.responseData?.businessLogo
      if(!logo) return null
      return `${process.env.VUE_APP_BUSINESS_PORTAL_WEBSITE}${logo}`
    },
    docTitle(){
      return this.responseData?.pageTitle
    }
  },
  async mounted(){
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get('token');
    this.refToken = urlParams.get('ref');
    this.businessId = urlParams.get('b')
    const showLogo = urlParams.get('showLogo')
    this.showLogo =  ['true', true, 1, '1'].includes(showLogo)
    this.fetchData()
  },
  methods:{
    async fetchData(){
      if(!this.token && !this.refToken) return
      const that = this
      let url
      if(this.token) url = `${process.env.VUE_APP_GET_SIGNUP_API_BASE_URL}?show_hidden_fields=true&token=${this.token}`
      else if(this.refToken && this.businessId) url = `${process.env.VUE_APP_GET_SIGNUP_API_BASE_URL}?show_hidden_fields=true&ref=${this.refToken}&b=${this.businessId}`
      if (this.language) url += `&lg=${this.language}`
      const response = await axios.get(
        url, 
        {
        headers: {
          'Authorization': `Bearer test`,
        },
      })
      if(response && response.data){
          that.responseData = response.data;
      }
    },
    changeLanguage(lang){
      this.language = lang
      if(lang==='ar') this.$vuetify.rtl = true
      else this.$vuetify.rtl = false
      this.fetchData()
    }
  },
  watch:{
    mainColor:{
      handler(){
        this.$vuetify.theme.themes.light.primary = this.mainColor
      },
      deep: true
    },
    docTitle: {
      handler(){
        document.title = this.docTitle
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/metropolis-2');
:root {
  --primary-color: #7F56DA;
}
body, html, .v-application{
    font-family: Metropolis !important;
    font-weight: 500;
}

.v-application--wrap{
  display: flex; 
  justify-content: center; 
  align-items: center;
  max-height: 100vh;
  overflow: hidden;
}
.upper-circle{
  width: 830px;
  height: 830px;
  top: -505px;
  right: -250px;
  border: 1px solid #C5B1F0;
  position: absolute;
  background-color: #E5DDF8;
  border-radius: 100rem
}
.bottom-circle{
  width: 1360px;
  height: 1360px;
  border: 1px solid #C5B1F0;
  position: absolute;
  background-color: #E5DDF8;
  border-radius: 100rem;
  top: 90px;
  left: -880px;
}
.logo{
  position: absolute;
  top: 30px;
  left: 30px;
  height: 90px;
}

@media screen and (max-width: 550px) {
  .main-container{
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh;
  }
  .upper-circle, .bottom-circle, .logo{
    display: none;
  }
}
</style>
