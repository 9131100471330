<template>
  <div>
    <div ref="recaptchaContainer"></div>
    <div v-if="error" style="color: red">{{ error }}</div>
  </div>
</template>

<script>
/* global grecaptcha */

export default {
  data() {
    return {
      error: null,
    };
  },
  mounted() {
    this.renderRecaptcha();
  },
  methods: {
    renderRecaptcha() {
      grecaptcha.render(this.$refs.recaptchaContainer, {
        sitekey: process.env.VUE_APP_RECAPTCHA_CLIENT_ID,
        callback: this.onRecaptchaVerified,
        'expired-callback': this.onRecaptchaExpired,
      });
    },
    onRecaptchaVerified(response) {
      this.$emit('onRecaptchaVerified', response)
    },
    onRecaptchaExpired() {
       this.$emit('onRecaptchaExpired')
    },
    resetRecaptcha() {
      grecaptcha.reset();
    },
  },
};
</script>