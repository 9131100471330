<template>
  <v-dialog v-model="dialogVisible" max-width="500">
    <v-card class="sw-dialog">
      <v-card-text>
        <p>{{ message }}</p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="$emit('closeDialog')">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    props:{
        message:{
            required: true,
            type: String
        },
    },
    computed:{
       dialogVisible: {
        get() {
            return this.message && this.message.length;
        },
        set() {
            // Handle the change if necessary
        },
    },
    }
}
</script>

<style>
.sw-dialog{
    display: flex !important;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.sw-dialog .v-card__text{
    font-size: 20px !important;
    font-weight: 500 !important;
    padding: 16px !important
}
</style>